import 'fullpage.js/vendors/scrolloverflow.min';
import 'scripts/fullpage.scrollHorizontally.min';
import fullpage from 'fullpage.js/dist/fullpage.extensions.min';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

const colors = {
  purpleDark: '#04002f',
  blueGrey: '#6a8196',
  turqoise: '#3B92A2',
  white: '#ffffff',
  darkGrey: '#34353b'
};

// animations
var resizeLogoTL = new TimelineMax();
if (window.matchMedia("(min-width: 769px)").matches) {
  resizeLogoTL
    .to(".brandanimation__image", 1, {
      width: '25%',
      transformOrigin: 'right top'
    });
} else {
  resizeLogoTL
    .to(".brandanimation__image", 1, {
      width: '75%',
      transformOrigin: 'right top'
    });
};

var fadeOutClaimTL = new TimelineMax();
fadeOutClaimTL
  .to("#claim", 1, {
    autoAlpha: 0
  });

var animation1TL = new TimelineMax({
  paused: true
});
animation1TL.from("#brand", 1.8, {
  top: "150%",
  ease: Power4.easeOut
});
animation1TL.from("#claim", 1.8, {
  top: "150%",
  ease: Power4.easeOut
});


var animation2TL = new TimelineMax({
  paused: true
});

// desktop
if (window.matchMedia("(min-width: 769px)").matches) {
  animation2TL.to('#brand', 2, {
      bezier: {
        values: [
          {left: '0', top: '36%'},
          {left: '0',top: '30%'},
          {left: '-1%',top: '24%'},
          {left: '-3%',top: '18%'},
          {left: '-6%',top: '12%'},
          {left: '-14%',top: '7%'},
          {left: '-25%',top: '4%'},
          {left: '-36%',top: '2%'}
        ]
      },
      ease: Power2.easeOut
    })
    .add(resizeLogoTL, 0)
    .add(fadeOutClaimTL, 0);
}
// mobile
else {
  animation2TL.to('#brand', 2, {
      bezier: {
        values: [
          {left: '0',top: '36%'},
          {left: '0',top: '30%'},
          {left: '-1%',top: '24%'},
          {left: '-2%',top: '18%'},
          {left: '-3%',top: '12%'},
          {left: '-4%',top: '7%'},
          {left: '-5%',top: '4%'},
          {left: '-6%',top: '2.5%'}
        ]
      },
      ease: Power2.easeOut
    })
    .add(resizeLogoTL, 0)
    .add(fadeOutClaimTL, 0);
};

// animation observer
var observer = new MutationObserver(function (mutations) {
  mutations.forEach(function (mutation) {
    if (mutation.attributeName === "class") {
      var attributeValue = $(mutation.target).prop(mutation.attributeName);
      if (($('.section.start').hasClass('active')) && ($('.section.start').hasClass('fp-completely'))) {
        //console.log('section.start is active.fp-completely' );
        // brand is at end position
        if (($('#brand').offset().left < -10)) {
          animation2TL.reverse(-.8);
        };
      };
    }
  });
});
observer.observe($(".section.start")[0], {
  attributes: true
});

function videoGotoAndPlay(video, itemCount, pos) {
  // desktop only
  if (window.matchMedia("(min-width: 769px)").matches) {
    var video = video.get(0);
    var videoDuration = video.duration;
    var partTime = videoDuration / itemCount;
    var startTime = partTime * pos;
    var endTime = startTime + partTime;
    if (endTime > videoDuration) {
      endTime = videoDuration;
    };
    video.currentTime = startTime;

    video.play();
    const onTimeUpdate = () => {
      if (video.currentTime >= (endTime - 0.05)) {
        video.pause();
      } else {
        window.requestAnimationFrame(onTimeUpdate);
      }
    };
    window.requestAnimationFrame(onTimeUpdate);
  };
};

new fullpage('#ueContainer', {
  licenseKey: '805A161D-DA8D49E9-B24A4DDC-AEEAEF7B',
  sectionsColor: [
    'transparent',
    colors.purpleDark,
    colors.blueGrey,
    'transparent',
    colors.white,
    'transparent',
    colors.darkGrey
  ],
  // Dont use same name as section#id! JETZT ALS DATA TAG!!
  anchors: [
    'Start',
    'About',
    'Features',
    'UseCases',
    'SupportedFormats',
    'Testimonials',
    'Contact'
  ],
  autoScrolling: true,
  scrollOverflow: true,
  controlArrows: true,
  slideSelector: '.section__slide',
  menu: '#menu',
  navigation: true,
  navigationPosition: 'right',
  navigationTooltips: ['Start', 'About', 'Features', 'Use Cases', 'Supported Formats', 'Testimonials', 'Contact'],
  slidesNavigation: true,
  scrollHorizontally: true,
  scrollHorizontallyKey: 'A1ED944A-F43D4226-B0135D16-A197BDE7',
  afterLoad: function (origin, destination, direction) {

  },
  onLeave: function (index, nextIndex, direction) {
    // leaving animation
    if (index.index == 0 && direction == 'down') {
      $('.brandanimation__claimwrapper').addClass('brandanimation__claimwrapper--hide');
      var itemCount = $('.features .section__slide').length;
      videoGotoAndPlay($('.features video'), itemCount, 0);
      animation2TL.restart();
    } else if (index.index == 1 && direction == 'up') {}
  },
  afterRender: function () {
    $('body').css({
      'visibility': 'visible',
      'background-color': '#fff',
    });
    animation1TL.restart();
  },
  onSlideLeave: function (section, origin, destination, direction) {
    // change edges color on slide
    const element = document.querySelector(`[data-anchor="${section.anchor}"]`);
    const prefix = 'edges--';

    if (element.classList.contains('edges')) {
      element.classList.forEach(className => {
        if (className.startsWith(prefix)) {
          element.classList.remove(className);
        }
      });

      if (section.anchor === 'UseCases') {
        if (destination.index === 0) {
          element.classList.add(prefix + 'turqoise');
        } else if (destination.index === 1) {
          element.classList.add(prefix + 'green');
        } else if (destination.index === 2) {
          element.classList.add(prefix + 'olive');
        } else if (destination.index === 3) {
          element.classList.add(prefix + 'yellow');
        } else if (destination.index === 4) {
          element.classList.add(prefix + 'red');
        } else if (destination.index === 5) {
          element.classList.add(prefix + 'bluegrey');
        } else if (destination.index === 6) {
          element.classList.add(prefix + 'purple_light');
        } else if (destination.index === 7) {
          element.classList.add(prefix + 'purple_medium');
        };
      }
      if (section.anchor === 'Testimonials') {
        if (destination.index === 0) {
          element.classList.add(prefix + 'bluegrey');
        } else if (destination.index === 1) {
          element.classList.add(prefix + 'purple_light');
        } else if (destination.index === 2) {
          element.classList.add(prefix + 'turqoise');
        };
      }
    }

    // control video on Features Section
    if (section.anchor == 'Features') {
      const sindex = destination.index;
      const itemCount = $('.features .section__slide').length;
      videoGotoAndPlay($('.features video'), itemCount, sindex);
    };
  }
});

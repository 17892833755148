$(function () {
  function writeVideoDesktop() {
    if( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ){
      // mobile

    }
    else {
      // desktop
      document.getElementById("introVideo").innerHTML = '<source src="public/videos/headerBg.mp4" type="video/mp4">';
      document.getElementById("featureVideo").innerHTML = '<source src="public/videos/featuresBg.mp4" type="video/mp4">';
    }
  }
  writeVideoDesktop();
});
